<template>
  <div class="container_fluid">
    <Headline />
    <section class="container">

      <div class="row">
        <div class="col-12 col-md-6">
          <div class="divider divider_bold ">
            <div class="text_white titlePage_subtitle titlePage_bold">
              <h3>MY CALENDAR</h3>
            </div>
          </div>
          <Calendar />
        </div>
        <Statistics />
      </div>
<!--      <div class="row">-->
<!--        <Sales />-->
<!--      </div>-->
<!--      <div class="row">-->
<!--        <Orders />-->
<!--      </div>-->
    </section>
  </div>
</template>

<script>

import { defineAsyncComponent } from 'vue'
const Sales = defineAsyncComponent(() => import(/* webpackChunkName: "sales" */'./dashboard/Sales.vue'))
const Orders = defineAsyncComponent(() => import(/* webpackChunkName: "orders" */'./dashboard/Orders.vue'))
const Headline = defineAsyncComponent(() => import(/* webpackChunkName: "headline" */'./dashboard/Headline.vue'))
const Statistics = defineAsyncComponent(() => import(/* webpackChunkName: "statistics" */'./dashboard/Statistics.vue'))
import Calendar from "@/components/commons/Calendar";

export default {
  name: 'Dashboard',
  components: {
    Calendar,
    Sales, Orders, Statistics, Headline
  },

  async mounted () {
    await this.$store.dispatch('users/getRole');
  },
  filters: {
    uppercase(value) {
      if (!value) return '';
      return value.toString().toUpperCase();
    },
    capitalize(value) {
      if (!value) return '';
      return value.toString()[0].toUpperCase() + value.toString().slice(1);
    },
  },

    methods:{
      isAdmin(){
          return localStorage.getItem('user_role') === 'admin';

      }
    },



};
</script>

<style scoped lang="scss">
.titlePage_subtitle {
  padding-left: 20px;
}

.divider{
  &:before{
    display: none;
  }
}

</style>
